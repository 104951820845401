import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import router from './router';
import store from './store';
import MasonryWall from '@yeger/vue-masonry-wall'
import { createMetaManager, defaultConfig, plugin as metaPlugin } from 'vue-meta'

createApp(App).use(router).use(store).use(MasonryWall).use(createMetaManager(false, {
    ...defaultConfig,
    meta: { tag: 'meta', nameless: true },
  })).use(metaPlugin).mount('#app')
