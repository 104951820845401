import http from "../http-common";
import authHeader from './auth-header';

const API_URL = 'instruments';

class InstrumentService {
    getAll(builder, location, opus, year, city, state, country, extant, searchOriginal, hasImages, hasStoplists, hasDocuments, hasWebpages, sort, limit, skip) {
        return http.get(API_URL, { 
            params: { 
                builder: builder ? builder.trim() : null, 
                location: location ? location.trim() : null, 
                opus: opus, 
                year: year, 
                city: city ? city.trim() : null, 
                state: state ? state.trim() : null, 
                country: country ? country.trim() : null, 
                extant: extant, 
                searchOriginal: searchOriginal, 
                hasImages: hasImages, 
                hasStoplists: hasStoplists, 
                hasDocuments: hasDocuments, 
                hasWebpages: hasWebpages, 
                sort: sort, 
                limit: limit, 
                skip: skip 
            },
            headers: authHeader()
        });
    }

    getAllPower(instrumentId, location1, locationIncludes1, location2, locationIncludes2, builder1, builderIncludes1, builder2, builderIncludes2, opus1, opusCondition1, opus2, opusCondition2, year1, yearCondition1, year2, yearCondition2, ranks1, ranksCondition1, ranks2, ranksCondition2, manuals1, manualsCondition1, manuals2, manualsCondition2, city1, cityIncludes1, city2, cityIncludes2, state1, stateIncludes1, state2, stateIncludes2, country1, countryIncludes1, country2, countryIncludes2, extant, playable, searchOriginal, hasImages, hasStoplists, hasDocuments, hasWebpages, sort, limit, skip) {
        return http.get(API_URL + `/power`, {
            params: { 
                instrumentId: instrumentId, 
                location1: location1 ? location1.trim() : null, 
                locationIncludes1: locationIncludes1, 
                location2: location2 ? location2.trim() : null, 
                locationIncludes2: locationIncludes2, 
                builder1: builder1 ? builder1.trim() : null, 
                builderIncludes1: builderIncludes1, 
                builder2: builder2 ? builder2.trim() : null, 
                builderIncludes2: builderIncludes2, 
                opus1: opus1, 
                opusCondition1: opusCondition1, 
                opus2: opus2, 
                opusCondition2: opusCondition2, 
                year1: year1, 
                yearCondition1: yearCondition1, 
                year2: year2, 
                yearCondition2: yearCondition2, 
                ranks1: ranks1, 
                ranksCondition1: ranksCondition1, 
                ranks2: ranks2, 
                ranksCondition2: ranksCondition2, 
                manuals1: manuals1, 
                manualsCondition1: manualsCondition1, 
                manuals2: manuals2, 
                manualsCondition2: manualsCondition2, 
                city1: city1 ? city1.trim() : null, 
                cityIncludes1: cityIncludes1, 
                city2: city2 ? city2.trim() : null, 
                cityIncludes2: cityIncludes2, 
                state1: state1 ? state1.trim() : null, 
                stateIncludes1: stateIncludes1, 
                state2: state2 ? state2.trim() : null, 
                stateIncludes2: stateIncludes2, 
                country1: country1 ? country1.trim() : null, 
                countryIncludes1: countryIncludes1, 
                country2: country2 ? country2.trim() : null, 
                countryIncludes2: countryIncludes2, 
                extant: extant,
                playable: playable,
                searchOriginal: searchOriginal,
                hasImages: hasImages,
                hasStoplists: hasStoplists, 
                hasDocuments: hasDocuments, 
                hasWebpages: hasWebpages, 
                sort: sort, 
                limit: limit, 
                skip: skip 
            },
            headers: authHeader()
        });
    }

    getAllPending() {
        return http.get(API_URL, { params: { pending: 1 }, headers: authHeader() });
    }

    get(id) {
        return http.get(API_URL + `/${id}`, { headers: authHeader() });
    }

    getbyOldID(id) {
        return http.get(API_URL + `/old/${id}`, { headers: authHeader() }).then(response => response.data);
    }

    create(data) {
        return http.post(API_URL, data, { headers: authHeader() });
    }

    update(id, data) {
        return http.patch(API_URL + `/${id}`, data, { headers: authHeader() });
    }

    delete(id) {
        return http.delete(API_URL + `/${id}`, { headers: authHeader() });
    }
}

export default new InstrumentService();