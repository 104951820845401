<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content}` : `Pipe Organ Database` }}</template>
    </metainfo>
    <div class="d-flex flex-column min-vh-100">
        <nav class="navbar navbar-expand-lg navbar-light shadow sticky-top" style="background-color: #f59f00" v-if="!$route.meta.hideHeader">
            <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse ms-0 ms-lg-4" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle text-uppercase" style="color: #343a40" href="/instruments" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Instruments
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/instruments">View/Search Instruments</a></li>
                                <li><a class="dropdown-item" href="/instruments/power">Instrument Power Search</a></li>
                                <li><hr class="dropdown-divider"></li>
                                <li><a class="dropdown-item" href="/images">View/Search Images</a></li>
                                <li><hr class="dropdown-divider"></li>
                                <li><a class="dropdown-item" href="/instruments/add"><span v-if="!loggedIn">Submit</span><span v-else>Add</span> New Instrument Entry</a></li>
                                <li><a class="dropdown-item" href="/related">Other Related Websites</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle text-uppercase" style="color: #343a40" href="/builders" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Builders
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/builders">View/Search Builders</a></li>
                                <li><hr class="dropdown-divider" v-if="loggedIn"></li>
                                <li><a class="dropdown-item" href="/builders/add" v-if="loggedIn">Add New Builder Entry</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle text-uppercase" style="color: #343a40" href="/locations" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Locations
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/locations">View/Search Locations</a></li>
                                <li><hr class="dropdown-divider" v-if="loggedIn"></li>
                                <li><a class="dropdown-item" href="/locations/add" v-if="loggedIn">Add New Location Entry</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <a class="navbar-brand mx-auto text-uppercase" href="/">Pipe Organ Database</a>
                <div class="collapse navbar-collapse me-0 me-lg-4" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a class="nav-link text-uppercase" style="color: #343a40" href="/about">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-uppercase" style="color: #343a40" href="/faq">FAQ</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-uppercase" style="color: #343a40" href="/contact">Contact</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-uppercase" style="color: #343a40" target="_blank"
                                href="https://organhistoricalsociety.org/product/donate-to-the-ohs/">Donate</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="container-fluid flex-grow-1 p-0" v-bind:class="{'bg-dark': !$route.meta.hideHeader}">
            <router-view />
        </div>
        <div class="container-fluid p-5 pt-4 pb-4 text-light" style="background-color: #343a40;" v-if="!$route.meta.hideHeader">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 mb-3 mb-md-0">
                    <h4 class="text-uppercase mb-0 mt-1" style="font-family: 'Oswald', sans-serif">
                        Pipe Organ Database
                    </h4>
                    <small class="text-secondary">A project of the
                        <a href="https://organhistoricalsociety.org/" target="_blank" class="text-secondary"
                            style="text-underline-offset: 0.25em">Organ Historical
                            Society</a></small>
                </div>
                <div class="col-12 col-md-6 text-md-end">
                    Website by
                    <a href="https://plenumorgans.com/" target="_blank" class="text-light ms-1"
                        style="text-underline-offset: 0.3em">John
                        Roper</a>
                    <a href="/login" class="text-light ms-md-3 d-block d-md-inline-block mt-3 mt-md-0"
                        style="text-underline-offset: 0.3em" v-if="!loggedIn" target="_blank">Editor Login</a>
                    <a href="/pending" class="text-light ms-md-3 d-block d-md-inline-block mt-3 mt-md-0"
                        style="text-underline-offset: 0.3em" v-if="loggedIn" target="_blank">Pending Items</a>
                    <a href="" @click="logOut" class="text-light ms-md-3 d-block d-md-inline-block mt-3 mt-md-0"
                        style="text-underline-offset: 0.3em" v-if="loggedIn">Logout</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AuthService from "./services/auth.service";
    export default {
        name: "app",
        computed: {
            loggedIn() {
                return this.$store.state.auth.status.loggedIn;
            }
        },
        methods: {
            logOut() {
                this.$store.dispatch("auth/logout");
                this.$router.push("/login");
            },
        },
        mounted() {
            if (this.loggedIn) {
                AuthService.verifyToken().then(
                    (response) => {
                        if (response.data.isValid == false) {
                            this.$store.dispatch("auth/logout");
                        }
                    }
                );
            }
        }
    };
</script>