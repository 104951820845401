import http from "../http-common";
import authHeader from './auth-header';

const API_URL = 'auth';

class AuthService {
    get(id) {
        return http.get(API_URL + `/user/${id}`, { headers: authHeader() });
    }

    getAll() {
        return http.get(API_URL + '/users', { params: { pending: 1 }, headers: authHeader() });
    }

    login(user) {
        return http.post(API_URL + '/login', {
            email: user.email,
            password: user.password
        }).then(response => {
            if (response.data.accessToken) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }

            return response.data;
        });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return http.post(API_URL + '/register', {
            name: user.name,
            email: user.email,
            password: user.password
        }, { headers: authHeader() });
    }

    update(id, user) {
        return http.post(API_URL + `/user/${id}/edit`, {
            name: user.name,
            email: user.email,
            password: user.password
        }, { headers: authHeader() });
    }

    verifyToken() {
        return http.get(API_URL + `/verifyToken`, { headers: authHeader() });
    }

    delete(id) {
        return http.delete(API_URL + `/${id}`, { headers: authHeader() });
    }
}

export default new AuthService();