import { createWebHistory, createRouter } from "vue-router";
import InstrumentService from "./services/instrument.service.js";

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("./components/Home")
    },
    {
        path: "/about",
        name: "about",
        component: () => import("./components/About")
    },
    {
        path: "/faq",
        name: "faq",
        component: () => import("./components/FAQ")
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import("./components/Contact")
    },
    {
        path: "/related",
        name: "related",
        component: () => import("./components/RelatedWebsites")
    },
    {
        path: "/login",
        name: "login",
        component: () => import("./components/Login")
    },
    {
        path: "/editors",
        name: "editors",
        component: () => import("./components/Editors")
    },
    {
        path: "/editors/register",
        name: "register",
        component: () => import("./components/EditUser.vue")
    },
    {
        path: "/editors/:id/edit",
        name: "updateUser",
        component: () => import("./components/EditUser.vue")
    },{
        path: "/editors/register",
        name: "register",
        component: () => import("./components/EditUser.vue")
    },
    {
        path: '/editors/profile',
        name: 'profile',
        component: () => import('./components/Profile.vue')
    },
    {
        path: '/builders',
        name: 'builders',
        component: () => import('./components/Builders.vue')
    },
    {
        path: '/builders/:id',
        name: 'builder',
        component: () => import('./components/Builder.vue')
    },
    {
        path: '/builders/:id/edit',
        name: 'builder-edit',
        component: () => import('./components/EditBuilder.vue')
    },
    {
        path: '/builders/add',
        name: 'builder-add',
        component: () => import('./components/EditBuilder.vue')
    },
    {
        path: '/locations',
        name: 'locations',
        component: () => import('./components/Locations.vue')
    },
    {
        path: '/locations/:id',
        name: 'location',
        component: () => import('./components/Location.vue')
    },
    {
        path: '/locations/:id/edit',
        name: 'location-edit',
        component: () => import('./components/EditLocation.vue')
    },
    {
        path: '/locations/add',
        name: 'location-add',
        component: () => import('./components/EditLocation.vue')
    },
    {
        path: '/instruments',
        name: 'instruments',
        component: () => import('./components/Instruments.vue')
    },
    {
        path: '/instruments/power',
        name: 'instrumentsPower',
        component: () => import('./components/InstrumentsPower.vue')
    },
    {
        path: '/instruments/:id',
        name: 'instrument',
        component: () => import('./components/Instrument.vue')
    },
    {
        path: '/instruments/:id/edit',
        name: 'instrument-edit',
        component: () => import('./components/EditInstrument.vue')
    },
    {
        path: '/instruments/add',
        name: 'instrument-add',
        component: () => import('./components/EditInstrument.vue')
    },
    {
        path: '/organ/:id',
        name: 'organ',
        beforeEnter: async (to, from, next) => {
            var instrument = await InstrumentService.getbyOldID(to.params.id);
            if (instrument) {
                next(`/instruments/${instrument.id}`);
            } else {
                console.log('Instrument not found');
                next('/404');
            }
        }
    },
    {
        path: '/images',
        name: 'images',
        component: () => import('./components/Images.vue')
    },
    {
        path: '/images/:id/edit',
        name: 'image-edit',
        component: () => import('./components/EditImage.vue')
    },
    {
        path: '/images/add',
        name: 'image-add',
        component: () => import('./components/EditImage.vue')
    },
    {
        path: '/documents/:id/edit',
        name: 'document-edit',
        component: () => import('./components/EditDocument.vue')
    },
    {
        path: '/documents/add',
        name: 'document-add',
        component: () => import('./components/EditDocument.vue')
    },
    {
        path: '/notes/:id/edit',
        name: 'note-edit',
        component: () => import('./components/EditNote.vue')
    },
    {
        path: '/notes/add',
        name: 'note-add',
        component: () => import('./components/EditNote.vue')
    },
    {
        path: '/stoplists',
        name: 'stoplists',
        component: () => import('./components/Stoplists.vue')
    },
    {
        path: '/stoplists/:id',
        name: 'stoplist',
        meta: {
            hideHeader: true
        },
        component: () => import('./components/Stoplist.vue')
    },
    {
        path: '/stoplists/:id/edit',
        name: 'stoplist-edit',
        component: () => import('./components/EditStoplist.vue')
    },
    {
        path: '/stoplists/add',
        name: 'stoplist-add',
        component: () => import('./components/EditStoplist.vue')
    },
    {
        path: '/consoles/:id/edit',
        name: 'console-edit',
        component: () => import('./components/EditConsole.vue')
    },
    {
        path: '/consoles/add',
        name: 'console-add',
        component: () => import('./components/EditConsole.vue')
    },
    {
        path: '/blowers/:id/edit',
        name: 'blower-edit',
        component: () => import('./components/EditBlower.vue')
    },
    {
        path: '/blowers/add',
        name: 'blower-add',
        component: () => import('./components/EditBlower.vue')
    },
    {
        path: '/webpages/:id/edit',
        name: 'webpage-edit',
        component: () => import('./components/EditWebpage.vue')
    },
    {
        path: '/webpages/add',
        name: 'webpage-add',
        component: () => import('./components/EditWebpage.vue')
    },
    {
        path: '/references/add',
        name: 'reference-add',
        component: () => import('./components/EditReference.vue')
    },
    {
        path: '/references/:id/edit',
        name: 'reference-edit',
        component: () => import('./components/EditReference.vue')
    },
    {
        path: '/pending',
        name: 'pendingItems',
        component: () => import('./components/PendingItems.vue')
    },
    {
        path: '/editRequests/add',
        name: 'editRequest-add',
        component: () => import('./components/EditRequest.vue')
    },
    {
        path: '/editRequests/:id/edit',
        name: 'editRequest-edit',
        component: () => import('./components/EditRequest.vue')
    },
    {
        path: '/500',
        name: 'systemerror',
        component: () => import('./components/500.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notfound',
        component: () => import('./components/404.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/profile', '/user'];
    const authRequired = publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router;